@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700);
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}


